import { useColorModeValue } from "@chakra-ui/react";

// TODO: set similar styles for all other controls
export const styles = {
    banner: {
      flex: 1,
      m:  0,
      p: 0,
      pt: ['60px', null, null, null, '100px', '100px'],
      pb: ['20px', null, null, null, '40px', '40px'],
      overflow: 'hidden',
    },
    container: {
      width: [null, null, null, null, null, null, '1390px'],
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
      gridGap: '0',
    },
    content: {
      div: {
        flex: 1,
        m:  0,
        p: 0,
        pt: ['60px', null, null, null, '100px', '120px'],
        pb: ['20px', null, null, null, '40px', null, '0'],
        overflow: 'hidden',
      },
      h3: {
        pl: [6,null, null, 10],
        pr: [4,null, null, 2],
        fontWeight: 'bold',
        fontFamily: 'poppins', 
        lineHeight: [1.39],
        letterSpacing: ['-.3px', '-.3px'],
        mb: ['15px', '', '', '', '20px'],
        width: ['100%'],
        maxWidth: ['100%', '', '', '90%', '100%', '540px'],
        fontSize: ['1.5rem', '', '', '1.4rem', '', '1.6rem', '1.6rem'],
      },
      p: {
        pl: [6, null, null, 10],
        pr: [4, null, null, 2],
        fontSize: '1.1rem', 
        fontFamily: 'poppins',
        lineHeight: '26px',
        mb: ['20px', '', '', '', '', '30px'],
        width: ['100%'],
        maxWidth: ['100%', '', '', '', '', '1410px'],
        br: {
          display: ['none', '', '', '', 'inherit'],
        },
      },
    },
    form: {
      mb: ['30px', null, null, null, null, '60px'],
      display: ['flex'],
      input: {
        borderRadius: ['4px'],
        backgroundColor: '#fff',
        width: ['240px', null, null, null, '315px', null, '375px'],
        height: ['45px', null, null, '55px', null, null, '65px'],
        padding: ['0 15px', null, null, '0 25px'],
        fontSize: [1, null, null, 2],
        border: 'none',
        outline: 'none',
        boxShadow: '0px 10px 50px rgba(48, 98, 145, 0.08)',
      },
      button: {
        fontSize: [1, null, null, null, 2, '20px'],
        borderRadius: ['4px'],
        padding: ['0 15px'],
        ml: ['10px'],
        width: ['auto', null, null, null, '180px'],
      },
    },
    image: {
      img: {
        display: 'flex',
        position: 'relative',
        top: ['0', null, null, null, null, '0'],
        maxWidth: ['100%', null, null, null, null, null, 'none'],
      },
    },
    partner: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      mb: ['40px'],
      '> div + div': {
        ml: ['10px', null, null, '20px', null, '30px'],
      },
      img: {
        display: 'flex',
      },
      span: {
        fontSize: [1, null, null, null, 2],
        color: '#566272',
        lineHeight: [1],
        opacity: 0.6,
        display: 'block',
        mb: ['20px', null, null, null, '0px'],
        mr: [null, null, null, null, '20px'],
        flex: ['0 0 100%', null, null, null, '0 0 auto'],
      },
    },
  };
  
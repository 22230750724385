import * as React from "react"
import {
  chakra,
  ImageProps,
  forwardRef,
  usePrefersReducedMotion,
} from "@chakra-ui/react"

import logo from "../../images/logo.svg";

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  return <chakra.img src={logo} ref={ref} {...props} />
})

import { Box, Text } from "@chakra-ui/react";
import { styles } from './LandingPage/styles'

const Footer = () => {

    return(
        <>
        <Box
            bottom={0}
            bgColor={'#091f2c'} 
            p={20}>
            <Text as="p" sx={styles.content.p} color={'#FFF'}>
                &copy; 2023 Center for Creative Leadership
            </Text>
        </Box>
        </>
    )

};

export default Footer;
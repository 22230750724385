import { Box, Heading, Text } from '@chakra-ui/react'
import { IPredictionDescription } from '../../interface/IPredictionDescription';

const PersonaDescription:React.FC<IPredictionDescription> = ({header,data}) => {
  
  return (
    <>  
    <Box
     id={header}
     wordBreak={'break-word'}
     >
      <Heading as={'h3'} size='lg'>
          {header}
      </Heading>
      <Text 
        as={'p'}
        mb={2}
        py={4}
        fontSize={'md'}>
          {data}
      </Text> 
    </Box>
    </>
  )
};

export default PersonaDescription;
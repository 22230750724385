import { Heading, Box, VStack, Text, UnorderedList, ListItem, Center, Stack, SlideFade, Card, CardBody, useColorModeValue } from "@chakra-ui/react";
import DataInputCard from "./DataInputCard";
import writeChallenges from '../../images/writeChallenges.svg';
import selectChallenges from '../../images/selectChallenges.svg';
import { styles } from "../LandingPage/styles";

const DataInputOption = (props: any) => {

    const bgcolor = useColorModeValue('#FFF', 'gray.700')

    const handleCheckboxSelection = () => {
        props.onCheckboxSelectionToggle();
    }

    const handleFormEntry = () => {
        props.onEntryFormSelectionToggle();
    }

    return (
        <>
            <Center p={[8, 4]}>
                <SlideFade
                    in={props.showOption}
                    offsetY={'20px'}
                    delay={0.4}>
                    <Card
                        bgColor={bgcolor}
                        variant={'outline'}>
                        <CardBody>

                            <VStack>
                                <Box pb={10}>
                                    <Text as="p" sx={styles.content.p}>
                                        Please select an option to enter your Leadership Challenges.

                                    </Text>


                                    <Text as="p" sx={styles.content.p}>
                                        The first option allows you to enter your challenges, providing a detailed description that the app uses to generate a personalized solution using AI.
                                    </Text>

                                    <Text as="p" sx={styles.content.p}>
                                        The second option allows you to choose 3 key leadership challenges from a curated list of challenges. Please note that the order of the challenges you select informs your predicted results.

                                    </Text>
                                </Box>

                                <Stack
                                    gap={4}
                                    direction={{ base: 'column', md: 'row' }}>

                                    <Center>
                                        <DataInputCard
                                            toggle={handleFormEntry}
                                            img={writeChallenges}
                                            description={"Describe your challenges for personalized result "} />
                                    </Center >


                                    <Center>

                                        <DataInputCard
                                            toggle={handleCheckboxSelection}
                                            img={selectChallenges}
                                            description={"Select 3 challenges from the list"} />

                                    </Center >


                                </Stack>
                            </VStack>
                        </CardBody>
                    </Card>

                    <Card mt={10} p={10} bgColor={bgcolor}
                        variant={'outline'}>

                        <Heading fontSize='sm'>
                            Disclaimer:
                        </Heading>

                        <CardBody>
                            <UnorderedList spacing="5" fontSize="xs">
                                <ListItem>The Leaderchip Challenge Experience app is currently being piloted.</ListItem>
                                <ListItem>Any inferences, conclusions, or decisions you make based on the app's output are solely your responsibility.</ListItem>
                                <ListItem>The app collects only the challenges you enter and generic usage statistics (e.g., date/time of app access, browser info, and generic server logs).</ListItem>
                                <ListItem>All data will be handled in accordance with CCL's Data Governance Standards and will never be shared with any third parties.</ListItem>
                                <ListItem>By using the app, you agree to these terms and conditions</ListItem>
                            </UnorderedList>

                        </CardBody>
                    </Card>
                </SlideFade>
            </Center>
        </>
    )
};

export default DataInputOption;
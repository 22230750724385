import { Link, Box, Flex, Text, Stack, Container, SimpleGrid, HStack, Card, CardBody, Grid, GridItem, Spacer, Tabs, TabPanels, TabPanel, TabList, Tab, VStack } from "@chakra-ui/react";
import SectionHeader from "../../utils/SectionHeader";
import { IManuscript, ISection, ISubsection } from "../../interface/IManuscript";
import FiguresTab from "./FiguresTab";
import TableCard from "./TableCard";

const AboutMeLayout = (props: any) => {

    const paper: IManuscript = props.paper;

    return (
        <>
            <Container maxW={'container.xl'}>
                <SimpleGrid>

                    <Stack direction={'column'}>
                        {paper ? (

                            <Stack>
                                <SectionHeader title={paper.heading.title} />

                                <Flex pb={10}>
                                    <Spacer />
                                    <HStack>
                                        {paper.heading.authors?.map((author: any, i: number) => (

                                            <Text key={i}><Link color={'teal.500'} href={author.url}>{author.last_name},{author.first_name}</Link>;</Text>

                                        ))}
                                    </HStack>
                                    <Spacer />
                                </Flex>


                                {Object.keys(paper.sections).map((s: any, si: number) => (
                                    <Grid
                                        key={si}
                                        templateRows='repeat(1, 1fr)'
                                        templateColumns='repeat(4, 1fr)'
                                        fontFamily={'Roboto'}
                                        fontWeight={300}
                                        textShadow='dark-lg'
                                        fontSize={'lg'}
                                        lineHeight={2}
                                        gap={4}>
                                        
                                        {/* Title of the section */}
                                        <GridItem colSpan={1}>
                                            <Text
                                                fontWeight={300}
                                                fontSize={'xl'}
                                                fontFamily={'Roboto'}>
                                                {s.replace("_", " ").toUpperCase()}

                                            </Text>
                                        </GridItem>
                                        {/* Text section */}
                                        <GridItem colSpan={3} textAlign={'justify'}>
                                            {Object.values(paper.sections[s as keyof ISection]).map((sub: ISubsection, idx: number) => (

                                                <Box key={si + idx}>

                                                    <Text fontWeight={500}>
                                                        {sub.title}
                                                    </Text>

                                                    {sub.description.map((desc: any, descid: number) => (

                                                    <Text pb={5} dangerouslySetInnerHTML={{ __html: desc}}>
                                                        
                                                    </Text>
                                                    ))}


                                                    <VStack gap={10}>
                                                        {/* Figures */}
                                                        <FiguresTab sub={sub} figures={props.figures} />

                                                        {sub.tables?.length > 0 ? (

                                                            <Card
                                                                mb={10}
                                                                w={'100%'}
                                                                overflow={'hidden'}
                                                                variant='elevated'
                                                                direction={{ base: 'column', sm: 'row' }}>

                                                                <CardBody p={0}>
                                                                    <>
                                                                        {/* {table.data.length > 0 ? (
                                                                                 */}
                                                                        <Tabs defaultIndex={0} >
                                                                            <>

                                                                                <TabList>
                                                                                    {sub.tables.map((table: any, tid: number) => (

                                                                                        <Tab key={table.title}>{table.title}</Tab>


                                                                                    ))}
                                                                                </TabList>

                                                                                <TabPanels p={0}>
                                                                                    {sub.tables.map((table: any, btid: number) => (

                                                                                        <TabPanel key={si + btid} p={0}>

                                                                                            <TableCard
                                                                                                headers={table.headers}
                                                                                                footnote={table.footnote}
                                                                                                title={table.title}
                                                                                                footnote_colspan={table.footnote_colspan}
                                                                                                caption={table.caption}
                                                                                                data={table.data} />
                                                                                        </TabPanel>
                                                                                    ))}

                                                                                </TabPanels>
                                                                            </>
                                                                        </Tabs>
                                                                    </>
                                                                </CardBody>

                                                            </Card>

                                                        ) : null}

                                                    </VStack>
                                                </Box>
                                            ))}

                                        </GridItem>

                                    </Grid>
                                ))}

                            </Stack>
                        ) : null}

                    </Stack>
                </SimpleGrid>

            </Container>

        </>
    )
};

export default AboutMeLayout;
import { 
    Center,
    SimpleGrid,
    Card,
    CardBody,
    CardFooter,
    Image,
    Text,
    Heading,
    Divider,
    Flex,
    VStack,
    Icon,
    Avatar,
    Spacer
} from "@chakra-ui/react";

// Icons
import { GiAbstract020, GiChaingun, GiLevelThreeAdvanced,  } from 'react-icons/gi';
import { styles } from '../../utils/sectionStyles';

// images
import background from '../../images/leaderLevels/background.jpg';
import lo from '../../images/leaderLevels/lo.svg';
import lm from '../../images/leaderLevels/lm.svg';
import lf from '../../images/leaderLevels/lf.svg';
import ic from '../../images/leaderLevels/ic.svg';
import ed from '../../images/leaderLevels/ed.svg';
import bmk from '../../images/leaderLevels/bmk.svg';
import { ILeaderLevel } from "../../interface/ILeaderLevel";


const LeaderLevelCard = (props:any) => {

    const leader_props = [
        { icon: lm, n_persona: 10, n_challenges: 10 },
        { icon: lf, n_persona: 0, n_challenges: 10 },
        { icon: ed, n_persona: 0, n_challenges: 6 },
        { icon: ic, n_persona: 0, n_challenges: 5 }, 
        { icon: bmk, n_persona: 0, n_challenges: 8 },
        { icon: lo, n_persona: 0, n_challenges: 11 },
    ]

    return(
        <>
        <SimpleGrid 
        p={{base: 2, md: 10}} 
        columns={{base: 1, md: 2, lg:3, xl: 3 }} 
        gap={20}>
        {props.levels.map((level:ILeaderLevel, idx:number) => (
            <Card 
            key={idx}
            sx={styles.card}>
                <CardBody p={0}>
                    <Image 
                        w='100%' 
                        h={40} 
                        roundedTop={'md'}
                        src={background} />
                    
                    <VStack h={40}>
                        <Avatar 
                            mt={-12} 
                            size='xl' 
                            bgColor={'gray.100'} 
                            color={'steelblue'} 
                            border={'4px solid #FFF'}
                            boxShadow={'md'}
                            marginBottom={10}
                            src={leader_props[idx].icon} />
                        <Heading
                            textTransform={'capitalize'}
                            textAlign={'center'}
                            fontSize={'md'} 
                            as={'h4'}>
                            {level.name}
                        </Heading>
                        <Text fontSize={'sm'}>
                            {level.program}
                        </Text>
                    </VStack>
                </CardBody>
                <Divider></Divider>
                <CardFooter>
                    <Flex width={'100%'}>
                        {/* Personas */}
                        <VStack>
                            <Icon as={GiChaingun} />
                            <Flex>
                            <Text
                                fontSize={'xs'}
                                colorScheme={'facebook'}
                                >{leader_props[idx].n_persona} Personas</Text>
                            </Flex>
                        </VStack>
                        <Spacer />
                        <Divider orientation='vertical' />
                        <Spacer />
                        {/* leader level abbr */}
                        <VStack>
                            <Icon as={GiLevelThreeAdvanced} />
                            <Flex>
                            <Text
                                fontSize={'xs'}
                                colorScheme={'facebook'}
                                >{level.abbr}</Text>
                            </Flex>
                        </VStack>
                        <Spacer />
                        <Divider orientation='vertical' />
                        <Spacer />
                        
                        {/* Challenges */}
                        <VStack>
                            <Icon as={GiAbstract020} />
                            <Flex>
                            <Text
                                fontSize={'xs'}
                                colorScheme={'facebook'}
                                >{leader_props[idx].n_persona} Challenges</Text>
                            </Flex>
                        </VStack>
                    </Flex>
                </CardFooter>
            </Card>
            ))}
        </SimpleGrid>

        </>
    )
};

export default LeaderLevelCard;
import { 
    Box,
    Text,
    Flex,
    Image,
    Heading,
    SimpleGrid,
    useColorModeValue
} from "@chakra-ui/react";
import "@fontsource/poppins";

import bannerImage from "../../images/landingPage/bannerImage.svg";
import { styles } from './styles';

const Banner = () => {
    return(
        <>
        <Box sx={styles.banner} id="banner" bgColor={useColorModeValue('white','gray.700')}>
            <SimpleGrid p={6} columns={{base: 1, md: 2}} gap={{base:10, md: 20}}>
                <Flex align={'center'} mt={{base:0, md:20}}>
                    <Box>
                        <Heading as="h3" 
                                sx={styles.content.h3}>
                        Leadership Challenges
                        </Heading>
                        <Text as="p" 
                            sx={styles.content.p}>
                            Leaders at all levels experience numerous challenges in the workplace, with meaningful opportunities to create structured development processes based on these challenges.
                        </Text>
                        <Text as="p" sx={styles.content.p}>
                            This application offers you a valuable opportunity to explore and gain an understanding of your unique challenges and offers insights on how fellow leaders may have addressed similar issues.
                        </Text>
                    </Box>
                </Flex>

                <Box sx={styles.image}>
                    <Image
                        flex={{base: 1, md: 'md'}}
                        w={500}
                        h={350}
                        src={bannerImage} />
                </Box>
            </SimpleGrid>
        </Box>
        </>
    )
};

export default Banner;
import { 
    Flex, 
    Text,
    Card, 
    Image,
    Badge,
    VStack,
    Spacer,
    Button,
    Heading, 
    Divider, 
    CardBody, 
    CardFooter,
    SimpleGrid,
} from "@chakra-ui/react";
import { useState } from "react";

// Components
import PersonaDrawer from "./PersonaDrawer";

// background and styles
import { styles } from "../../utils/sectionStyles";
import { PersonaBackground } from "../../utils/PersonaBackgrounds";

// interface
import { IChallengeExperience } from "../../interface/IChallengeExperience";
import { IPersona } from "../../interface/IPersona";

// Icons
import { RiQuestionAnswerFill } from "react-icons/ri"
import { RiUserSearchFill } from "react-icons/ri"
import { FiCloudSnow } from "react-icons/fi"

const PersonaCard = (props:any) => {

    // For opening drawer
    const [ isOpen, setIsOpen ] = useState(false)
    const [ drawerTitle, setDrawerTitle ] = useState('')
    const [ personaDescription, setpersonaDescription ] = useState<string|IChallengeExperience[]>()


    const handlePersonaDrawer = (value: string | IChallengeExperience[], title: string) => {
        setDrawerTitle(title);
        setIsOpen(!isOpen);
        setpersonaDescription(value);
    }

    
    const handleClose = () => {
        setDrawerTitle('')
        setIsOpen(!isOpen);
        setpersonaDescription('')
    }
    
    const getGroupNumber = (name:string) => {

        let num = parseInt(name.match(/\d+/g)![1])
        
        return(num)        
    }

    return(
        <>
        <SimpleGrid 
            gap={8}  
            p={{base: 2, md: 10}}
            columns={{base: 1, md: 2, lg:3, xl: 4}}>
            {props.personas.map((persona:IPersona,idx:number) => (
            <Card 
                key={idx}>
                <CardBody padding={0}>

                    <Flex p={'1.25rem'}>
                        <VStack>
                            <Text as="p" mb={'-2'} fontSize={'0.4em'}>TDA Group</Text>
                            <Heading
                                letterSpacing={'0.06em'}
                                flex={'flex-start'}
                                fontSize={'2em'}
                                fontFamily={'anton'}>
                                {getGroupNumber(persona.tda_group_name).toLocaleString('en-US', {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false
                                })}
                            </Heading>
                            <Divider h='0.5' bg={'red'} my='2'/>
                        </VStack>

                        <Spacer />
                        
                        <Flex
                            flex={'flex-end'}
                            align={'start'}>
                            <Badge
                                colorScheme={'blue'}
                                variant='outline'
                                fontSize={'sm'}>
                                {persona.leader_level}
                            </Badge>
                        </Flex>
                    </Flex>

                    <Flex>
                        <VStack align={'left'}>
                            <Heading
                                as={'h3'}
                                fontSize={'x-large'}
                                pl={'1.25rem'}
                                pt={'2rem'}>
                                    {persona.name}
                            </Heading>
                        
                            <Text
                                p={'1.25rem'}
                                align={'left'} 
                                fontSize={'md'}>
                                {persona.description}
                            </Text>

                            <Image
                                src={PersonaBackground.get(persona.name.toLowerCase())} />
                        </VStack>
                    </Flex>

                </CardBody>
                <Divider />
                <CardFooter>
                    <Flex w={'100%'} align={'center'}>
                        <Spacer />
                        
                        <Button 
                            onClick={() => handlePersonaDrawer(persona.why, 'Why')}
                            variant={'ghost'}
                            colorScheme={'blue'}
                            size={'sm'}
                            leftIcon={<RiQuestionAnswerFill />}
                            aria-label={'Why'}>
                            <Text
                                textTransform={'capitalize'}>
                                Why
                            </Text>
                        </Button>
                        
                        <Spacer />

                        <Button 
                            onClick={() => handlePersonaDrawer(persona.how, 'How')}
                            variant={'ghost'}
                            colorScheme={'blue'}
                            size={'sm'}
                            leftIcon={<RiUserSearchFill />}
                            aria-label={'How'}>
                            <Text
                                textTransform={'capitalize'}>
                                How
                            </Text>
                        </Button>
                                                            
                        <Spacer />

                        <Button 
                            onClick={() => handlePersonaDrawer(persona.challenge_experiences, 'Challenge Experiences')}
                            variant={'ghost'}
                            colorScheme={'blue'}
                            size={'sm'}
                            leftIcon={<FiCloudSnow />}
                            aria-label={'Challenges'}>
                            <Text
                                textTransform={'capitalize'}>
                                Challenges
                            </Text>
                        </Button>                        

                        <Spacer />
                    </Flex>
                </CardFooter>
            </Card>
            ))}
        </SimpleGrid>

        <PersonaDrawer
            isOpen={isOpen}
            onClose={handleClose}
            title={drawerTitle}
            description={personaDescription}/>
        </>
    )
};

export default PersonaCard;
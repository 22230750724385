import { 
    Modal, 
    ModalBody, 
    ModalHeader, 
    ModalContent,
    ModalOverlay, 
    ModalCloseButton,
    ModalFooter, 
} from "@chakra-ui/react"

const ChallengeCardModal = (props:any) => {

    const handleClose = () => {
        props.onClose();
    }
    
    return (
      <>
        <Modal 
            size={['lg','md','md']}
            isOpen={props.isOpen} 
            onClose={handleClose} 
            motionPreset='slideInBottom'
            isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.challenge.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {props.challenge.description}  
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
      </>
    )
  }

  export default ChallengeCardModal;

// const BASE_URL = process.env.REACT_APP_API_BASE_URL
const BASE_URL = 'https://leadership-challenge-experience-backend-dev.ccl-k8.org/'

export const FULL_CHALLENGE_URL = BASE_URL + 'challenges/full';
export const CHALLENGE_URL = BASE_URL + 'challenges/';
export const TOPIC_PROBABILITIES_URL = BASE_URL + 'challenge-probabilities/';
export const GROUP_CLASSIFIER_URL = BASE_URL + 'persona-classifier/';
export const COUNTRIES_URL = BASE_URL + 'countries/';
export const LCL_MODEL_URL = BASE_URL + 'lcl-models/';
export const LEADER_LEVEL_URL = BASE_URL + 'leader-levels/';
export const PERSONA_URL = BASE_URL + 'personas/';
export const TOPIC_THEMES_URL = BASE_URL + 'topic-themes/';
export const MANUSCRIPT_URL = BASE_URL + 'manuscript/';
export const SUPPLEMENTAL_METHOD_URL = BASE_URL + 'supplemental/';
export const GEO_LOCATION_URL = 'https://ipinfo.io/json?token=9db79898db6c55';
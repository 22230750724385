import { Avatar, Card, CardBody, Flex, Heading, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";

// interface
import { ITopicTheme } from "../../interface/ITopicTheme";

// layout
import { styles } from "../../utils/sectionStyles";
import { themeBgColors } from '../../utils/themeBgColors';
import { ChallengeThemeBackground } from "./ChallengeThemeBackground";


// images and icons



const ChallengeThemeCard = (props:any) => {

    
    return(
        <>
        <Flex 
            pb={40}
            align={'center'} justifyContent={'center'}>
        <SimpleGrid 
            p={{base: 2, md: 10 }}
            columns={{base: 1, md: 2, lg:3}} 
            gap={8}>
            {props.themes.map((theme:ITopicTheme,idx:number) => (
                <Card
                    sx={styles.card}
                    borderRadius={'xl'}
                    key={idx}>
                    <CardBody p={'0 !important'}>
                        <VStack>
                            <Image
                                h={['8rem', '10rem', '12rem']}
                                w={'100%'}
                                mb={'-2.5rem'}
                                borderTopRadius={'xl'}
                                objectFit={'cover'}
                                src={ChallengeThemeBackground.get(theme.name.split(" ").join(""))?.image} />

                            <Avatar 
                                size='lg' 
                                bgColor={'gray.100'} 
                                color={themeBgColors[idx]} 
                                border={'4px solid #FFF'}
                                boxShadow={'md'}
                                marginBottom={2} 
                                icon={ChallengeThemeBackground.get(theme.name)?.icon}/>
                            <Heading
                                textTransform={'capitalize'}
                                textAlign={'center'}
                                fontSize={'md'} 
                                fontWeight={700}
                                color={themeBgColors[idx]}
                                as={'h4'}>
                                {theme.name}
                            </Heading>
                            <Text
                                p={'2rem'}
                                fontSize={'md'}>
                                {theme.description}
                            </Text>
                        </VStack>  
                    </CardBody>                     
                </Card>
            ))}
            </SimpleGrid>
        </Flex>
        </>
    )
};
export default ChallengeThemeCard;
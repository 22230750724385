import { Flex, Spinner } from "@chakra-ui/react"

const LCLSpinner = () => {
    return(
        <>
        <Flex
            minH={'75vh'}
            fontSize={'2xl'}
            justifyContent={'center'}
            alignItems={'center'}>
                <Spinner 
                    bgColor={'whiteAlpha.100'}
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl' />
        </Flex>
        </>
    )
};

export default LCLSpinner;
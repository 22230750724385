import { useEffect, useState } from "react";
import { Flex, VStack, Box } from "@chakra-ui/react";

// Interface
import { IChallengeTopic } from '../../interface/IChallengeTopic';

// URL
import { FULL_CHALLENGE_URL } from '../../utils/APIURL';

// Header
import ListFilter from './ListFilter';
import SectionHeader from '../../utils/SectionHeader';
import LCLSpinner from '../../utils/LCLSpinner';
import DisplayError from '../../utils/DisplayError';
import ChallengeCard from './ChallengeCard';
import { GET } from "../../utils/fetchData";

const ChallengeTopic = () => {
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [challengeTopics, setChallengeTopics] = useState<Array<IChallengeTopic>>([]);
    const [filterValue, setFilterValue] = useState('')

    const getChallengeTopics = async () => {
        setLoading(true);

        await GET(FULL_CHALLENGE_URL)
            .then(response => {
                setChallengeTopics(response.data.themes);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false)
            });
            
        }
        
        useEffect(() => {
            getChallengeTopics();
        }, []);

        return(
            <>
            <Flex padding={10}>
                <SectionHeader 
                    title={'Leadership Challenges'} />
            </Flex>
            
            {error ? ( <DisplayError errors={error} /> ) : null }


            {loading ? (
                <LCLSpinner />
            ) : (
                <VStack>

                    {/* <ListFilter setFilterValue={setFilterValue} /> */}

                    <ChallengeCard
                        challengeTopics={challengeTopics} 
                        filterValue={filterValue} />
            
                </VStack>
            )}
            </>
        )
}

export default ChallengeTopic;
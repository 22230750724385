import axios from 'axios';
import { useEffect, useState } from "react";

import {
    Flex,
    Spinner,
    Card,
    CardBody,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Avatar,
    VStack,
    Box,
} from "@chakra-ui/react";
import { ITopicTheme } from "../../interface/ITopicTheme";

import SectionHeader  from '../../utils/SectionHeader'


import { TOPIC_THEMES_URL } from '../../utils/APIURL';
import { GET } from '../../utils/fetchData';
import ChallengeThemeCard from './ChallengeThemeCard';
import DisplayErrors from '../../utils/DisplayError';
import LCLSpinner from '../../utils/LCLSpinner';

const TopicThemes = () => {
    
    const [themes, setThemes] = useState<Array<ITopicTheme>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    
    const getThemes = async () => {
        setLoading(true);

        await GET(TOPIC_THEMES_URL)
            .then(response => {
                setThemes(response.data);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false)
            });

    }

    useEffect(() => {
        getThemes();
    }, []);

    return(
        <>
        <SectionHeader title={'Leadership Challenge Themes'} />

        {error ? ( <DisplayErrors errors={error} /> ) : null }

        {loading ? (
            
            <LCLSpinner />

        ) : (      
        <ChallengeThemeCard themes={themes} />
        )}
        </>  
    );
};


export default TopicThemes;
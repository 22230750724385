import { FaChevronRight } from "react-icons/fa";
import { INavItem } from "../../../interface/INavItem";
import { 
    Box, 
    HStack, 
    Link, 
    useColorModeValue, 
    Text, 
    Flex,
    Icon
} from "@chakra-ui/react";

export const DesktopSubNav = ({ label, href, subLabel }: INavItem) => {
    return(
        <Link
            href={href}
            role={'nav'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('purple.50', 'gray.900')}}>
            <HStack align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'purple.200' }}
                        fontWeight={500}>
                            {label}
                    </Text>
                    <Text fontSize={'sm'}>{ subLabel }</Text>
                </Box>

                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                        <Icon 
                            color={'purple.400'}
                            w={6}
                            h={6}
                            as={FaChevronRight} />
                </Flex>
            </HStack>
        </Link>
    );
};
import { Table, TableCaption, Thead, Tr, Th, Tbody, Td, Tfoot } from '@chakra-ui/react';
import './tableStyle.css';

const TableCard = (props: any) => {
    return (
        <>
            <Table variant='striped' mt={10} mb={10} size={'sm'} overflow={'visible'} layout={5}>
                <TableCaption placement='top'>{props.title}:&nbsp;{props.caption}</TableCaption>
                <Thead>
                    {props.headers.map((header: any, hid: number) => (
                        <Tr key={hid}>

                            {header.head.map((head: any, nid: number) => (

                                <Th key={nid} align='center'
                                    p={1}
                                    fontSize={'0.55em'}
                                    colSpan={head.colspan} textAlign='center'>{head.name}</Th>
                            ))}

                        </Tr>
                    ))}

                </Thead>

                <Tbody>
                    {props.data.map((d: any, id: number) => (
                        <Tr key={id}>
                            {Object.keys(d).map((datakey: any, did: number) => (
                                <Td key={did}
                                    p={1}
                                    dangerouslySetInnerHTML={{ __html: d[datakey as keyof string] }} fontSize={'0.55em'}></Td>
                            ))}
                        </Tr>
                    ))}

                </Tbody>

                <Tfoot>
                    <Tr>
                        <Td colSpan={props.footnote_colspan} dangerouslySetInnerHTML={{ __html: props.footnote }} fontSize={'sm'}></Td>
                    </Tr>
                </Tfoot>
            </Table>

        </>
    )
}

export default TableCard;
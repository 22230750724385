import { Heading, Image, Stack, Text, Tab, TabList, TabPanel, TabPanels, Tabs, Divider } from "@chakra-ui/react";




const FiguresSubTab = (props: any) => {

    // var Figures: string[] = props.figures;
    
    const fetchFigures = (word: string) => {
        word = word.replace(' ', '')
        var regex = new RegExp(word + '[^0-9]', 'g')
        var figures = props.figures.filter((e: string) => e.match(regex))
        
        return figures;
    }

    const getFigureNames = (word: string) => {

        word = word.replace(' ', '')

        var figures = fetchFigures(word)
        var regex = new RegExp(word + "([a-zA-Z]?)", "g")
        var figureNames = figures.join('|').match(regex);


        return figureNames || [];
    }



    return (
        <>
            <Tabs variant='soft-rounded' colorScheme='blue' size={'sm'}>
                {props.figureName && getFigureNames(props.figureName)?.length > 1 ? (

                    <TabList>
                        {getFigureNames(props.figureName)?.map((fn: any, id: number) => (

                            <Tab key={id}>{fn.replace(/Figure(\d+)/g, " $1")}</Tab>

                        ))}
                    </TabList>
                ) : null}

                <TabPanels>

                    {fetchFigures(props.figureName).map((fig: any, fid: number) => (
                        <TabPanel key={fid}>
                            <Stack>
                                <Image
                                    w={'95%'}
                                    objectFit={'cover'}
                                    src={fig} />

                                {fig && <Divider></Divider>}

                                <Heading size='sm'>{props.figureTitle}</Heading>

                                <Text py='2' fontSize={'sm'}>
                                    {props.figureLegend}
                                </Text>

                            </Stack>
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </>
    )
}

export default FiguresSubTab;
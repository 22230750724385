import {
    Box,
    Card,
    Text,
    Flex,
    Stack,
    Button,
    Image,
    Spacer,
    Heading,
    CardBody,
    CardFooter,
    SimpleGrid,
    useColorModeValue,
    Divider,
    CardHeader,
    HStack,
} from "@chakra-ui/react";

// icons
import { getChallengeBackground } from "../../utils/challengeBackground";
import { themeBgColors } from "../../utils/themeBgColors";


const ChallengeCard = (props: any) => {
    const cardColor = useColorModeValue('#FFFFFF', 'gray.900')

    return (
        <>
            {props.challengeTopics.map((theme: any, idx: number) => (
                <Box
                    key={idx}
                    w={'100%'}
                    mt={'0px !important'}
                    pt={5}
                    pb={20}
                    pl={5}
                    _even={{ background: "rgba(0,0,0,0.02)" }}>

                    <Box p={2}>
                        <HStack>
                            <Box
                                pt={2}
                                pb={2}
                                h={'100%'}
                                borderLeft={'5px'}
                                borderLeftColor={themeBgColors[idx]}
                                borderLeftStyle={'solid'}>&nbsp;
                            </Box>

                            <Heading
                                as='h3'
                                fontSize={'2xl'}
                                fontFamily={'Roboto'}
                                fontWeight={400}>
                                {theme.name}
                            </Heading>
                        </HStack>

                    </Box>

                    <SimpleGrid
                        p={{ base: 2, md: 10 }}
                        columns={{ base: 1, sm: 2, md: 3, lg: 3, xl: 4 }}
                        gap={4}>

                        {theme.challenges.map((challenge: any, i: number) => (

                            <Card
                                key={i}
                                maxW='sm'
                                borderTopColor={themeBgColors[idx]}
                                borderTopWidth={4}
                                rounded={'md'} >
                                <CardBody p={0}>
                                    <Image
                                        rounded={'md'}
                                        objectFit='cover'
                                        src={getChallengeBackground.get(challenge.name)}
                                        alt={challenge.name} />
                                    <Divider></Divider>

                                    <Stack mt='6' spacing={6} pl={4} pr={4} pb={6}>
                                        <Heading
                                            size='md'
                                            fontWeight={500}
                                            fontFamily={'Roboto'}>{challenge.name}</Heading>
                                        <Text noOfLines={[2, 4, 6]}>
                                            {challenge.description}
                                        </Text>
                                    </Stack>

                                </CardBody>
                            </Card>

                        ))}
                    </SimpleGrid>
                </Box>

            ))}

        </>
    )
};

export default ChallengeCard;
import adverseWorkEnvironment from "../images/challenges/adverseWorkEnvironment.svg"
import ambiguousResponsibilities from "../images/challenges/ambiguousResponsibilities.svg"
import challengingBusinessContext from "../images/challenges/challengingBusinessContext.svg"
import changeAndStability from "../images/challenges/changeAndStability.svg"
import changingEnvironment from "../images/challenges/changingEnvironment.svg"
import competingPriorities from "../images/challenges/competingPriorities.svg"
import competingPriorities3 from "../images/challenges/competingPriorities3.svg"
import credibilityGaps from "../images/challenges/credibilityGaps.svg"
import crossBoundaryInfluence from "../images/challenges/crossBoundaryInfluence.svg"
import crossFunctionalInfluence from "../images/challenges/crossFunctionalInfluence.svg"
import deficientOperationalProcesses from "../images/challenges/deficientOperationalProcesses.svg"
import developmentGaps from "../images/challenges/developmentGaps.svg"
import dynamicBusinessEnvironment from "../images/challenges/dynamicBusinessEnvironment.svg"
import employeeEngagement from "../images/challenges/employeeEngagement.svg"
import firstTimeManagingPeople2 from "../images/challenges/firstTimeManagingPeople2.svg"
import frustrationWithOthers from "../images/challenges/frustrationWithOthers.svg"
import ineffectiveInterpersonalStyle from "../images/challenges/ineffectiveInterpersonalStyle.svg"
import interpersonalRigidity from "../images/challenges/interpersonalRigidity.svg"
import lackCooperation from "../images/challenges/lackCooperation.svg"
import limitedInfluence from "../images/challenges/limitedInfluence.svg"
import frustrationWithPeopleTime from "../images/challenges/frustrationWithPeopleTime.svg"
import limitedMarketGrowth from "../images/challenges/limitedMarketGrowth.svg"
import limitedSelfAwareness from "../images/challenges/limitedSelfAwareness.svg"
import multipleCompetingPriorities from "../images/challenges/multipleCompetingPriorities.svg"
import newToPositionalAuthority from "../images/challenges/newToPositionalAuthority.svg"
import organizationalTalentIssues from "../images/challenges/organizationalTalentIssues.svg"
import organizationalUncertainty from "../images/challenges/organizationalUncertainty.svg"
import peerAsTeamMember from "../images/challenges/peerAsTeamMember.svg"
import personalImprovement from "../images/challenges/personalImprovement.svg"
import personalLimitation from "../images/challenges/personalLimitation.svg"
import problemsWithUpperManagement from "../images/challenges/problemsWithUpperManagement.svg"
import processImprovementAcrossGroups from "../images/challenges/processImprovementAcrossGroups.svg"
import resourceConstraints from "../images/challenges/resourceConstraints.svg"
import staffingIssues from "../images/challenges/staffingIssues.svg"
import strategicResponsibilities from "../images/challenges/strategicResponsibilities.svg"
import supportForChange from "../images/challenges/supportForChange.svg"
import strategicAlignment from "../images/challenges/strategicAlignment.svg"
import talentPipelineIssues from "../images/challenges/talentPipelineIssues.svg"
import teamPerformance from "../images/challenges/teamPerformance.svg"
import tenseAndComplexSituation from "../images/challenges/tenseAndComplexSituation.svg"
import transitionIntoANewRole from "../images/challenges/transitionIntoANewRole.svg"
import workforceConstraints from "../images/challenges/workforceConstraints.svg"
import accountabilityForOthersWork from "../images/challenges/accountabilityForOthersWork.svg"

export const getChallengeBackground = new Map<string, string>(
  [
    ["Accountability for Others’ Work", accountabilityForOthersWork],
    ["Adverse Work Environment", adverseWorkEnvironment],
    ["Challenging Business Context", challengingBusinessContext],
    ["Change and Instability", changeAndStability],
    ["Changing Internal/External Environment", changingEnvironment],
    ["Competing People and Project Priorities", competingPriorities],
    ["Competing Priorities", competingPriorities3],
    ["Credibility Gaps", credibilityGaps],
    ["Cross-Boundary Influence", crossBoundaryInfluence],
    ["Cross-Functional Influence", crossFunctionalInfluence],
    ["Deficient Operational Processes", deficientOperationalProcesses],
    ["Development Gaps: Yours & Others", developmentGaps],
    ["Development Gaps: Yours and Others", developmentGaps],
    ["Dynamic Business Environment", dynamicBusinessEnvironment],
    ["Employee Engagement and Performance", employeeEngagement],
    ["Employee Engagement", employeeEngagement],
    ["First Time Managing People", firstTimeManagingPeople2],
    ["Frustrations with Others", frustrationWithOthers],
    ["Frustrations with People and Time", frustrationWithPeopleTime],
    ["Ineffective Interpersonal Style", ineffectiveInterpersonalStyle],
    ["Interpersonal Rigidity", interpersonalRigidity],
    ["Lack of Cooperation", lackCooperation],
    ["Limited Influence", limitedInfluence],
    ["Limited Market/Sales Growth", limitedMarketGrowth],
    ["Limited Self-Awareness", limitedSelfAwareness],
    ["Multiple Competing Priorities", multipleCompetingPriorities],
    ["New or Ambiguous Responsibilities", ambiguousResponsibilities],
    ["New to Positional Authority", newToPositionalAuthority],
    ["Organizational Readiness Amid Uncertainty", organizationalUncertainty],
    ["Organizational Talent Issues", organizationalTalentIssues],
    ["Peers as Team Members", peerAsTeamMember],
    ["Personal Improvement", personalImprovement],
    ["Personal Limitations", personalLimitation],
    ["Problems with Upper Management", problemsWithUpperManagement],
    ["Process Improvement across Groups", processImprovementAcrossGroups],
    ["Resource Constraints and Financial Instability", resourceConstraints],
    ["Staffing Issues", staffingIssues],
    ["Strategic Alignment", strategicAlignment],
    ["Strategic Responsibilities", strategicResponsibilities],
    ["Support for Change", supportForChange],
    ["Talent Pipeline Issues", talentPipelineIssues],
    ["Team Performance", teamPerformance],
    ["Tense and Complex Situations", tenseAndComplexSituation],
    ["Transition into a New Role", transitionIntoANewRole],
    ["Workforce Constraints", workforceConstraints],
  ]
);
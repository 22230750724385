import "@fontsource/anton";
import { useState, useEffect } from "react";
import axios from 'axios';
import { PERSONA_URL } from "../../utils/APIURL";

import { IPersona } from "../../interface/IPersona";
import { 
    Flex,
    Spinner
} from "@chakra-ui/react";
import SectionHeader from "../../utils/SectionHeader";

import PersonaCard from "./PersonaCard";
import { GET } from "../../utils/fetchData";
import DisplayError from "../../utils/DisplayError";
import LCLSpinner from "../../utils/LCLSpinner";


const Personas = () => {
    const [personas, setPersonas] = useState<Array<IPersona>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    

    const getPersonas = async () => {
        setLoading(true);

        await GET(PERSONA_URL)
            .then(response => {
                setPersonas(response.data);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false)
            });
        }

        useEffect(()=>{
            getPersonas();
        }, []);

        return(
            <>
            <Flex>
                <SectionHeader title={'Leadership Challenge Experiences'}/>
            </Flex>
            
            {error ? ( 
                <DisplayError errors={error} />
            ) : (
                <>
                {loading ? (
                    <LCLSpinner />
                ) : (
                <Flex >
                    <PersonaCard personas={personas} />
                </Flex>
                )}
                </>
            )}
            </>
        )
}

export default Personas;
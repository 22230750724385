import { Card, CardBody, Center, Flex } from "@chakra-ui/react";

const DisplayError = (props:any) => {

    return(
        <>
        <Center>
        <Flex
            minH={'75vh'}
            fontSize={'sm'}
            justifyContent={'center'}
            alignItems={'center'}>
                <Card
                    borderRight={'2px solid red'} 
                    borderLeft={'2px solid red'}>
                    <CardBody
                        color={'#e91e63'}>
                        {props.errors.code}: {props.errors.message}
                    </CardBody>
                </Card>
        </Flex>
        </Center>
        </>
    )

};

export default DisplayError;
import { Box, Center, Heading, useColorModeValue } from "@chakra-ui/react";

const DataInputCard = (props: any) => {

    const cardColor = useColorModeValue('rgba(1,1,1,0.7)', 'rgba(0,0,0,0.7)')
    const textColor = useColorModeValue('#FFF', '#FFF')

    const handleClick = () => {
        props.toggle();
    }
    return (
        <>
            <Box
                rounded={'md'}
                border={'1px'}
                textAlign={'left'}
                bgImage={props.img}
                onClick={handleClick}
                borderColor={'gray.200'}
                transition={"filter ease 0.5s"}
                style={{ filter: "grayscale(1)" }}
                h={{ base: '20em', sm: '20em', md: '25em', lg: '30em' }}
                w={{ base: '20em', sm: '20em', md: '25em', lg: '30em' }}
                _hover={{
                    boxShadow: '0px 0px 5px #ddd',
                    transition: "filter ease 0.5s",
                    filter: "grayscale(0) !important",
                    cursor: 'pointer'
                }}>
                <Box
                    p={4}
                    w={{ base: '19.9em', sm: '19.8em', md: '24.8em', lg: '29.8em' }}
                    h={'100px'}
                    bottom={'0px'}
                    position={'absolute'}
                    borderBottomRadius={'md'}
                    bgColor={cardColor}>
                    <Heading
                        as={'h3'}
                        color={textColor}
                        fontWeight={500}
                        fontSize={{ md: '1rem', sm: '0.7rem' }}>

                        {props.description}

                    </Heading>
                </Box>
            </Box>
        </>
    )
};

export default DataInputCard;
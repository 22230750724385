import React from "react";
import { useField } from "formik";
import { TextInput } from "./TextInput";
import { TextInputProps } from "../Props/TextInputProps";

export const FormikTextInput: React.FC<TextInputProps> = (props) => {
    const [ field, meta ] = useField(props.name);
    return(
        <>
        <TextInput id={field.name} {...field} {...props} errorText={meta.error} />
        </>
    )
}
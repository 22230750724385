import { useEffect, useState } from "react";
import { MANUSCRIPT_URL } from "../../utils/APIURL";
import { GET } from "../../utils/fetchData";
import { IManuscript } from "../../interface/IManuscript";
import LCLSpinner from "../../utils/LCLSpinner";
import DisplayError from "../../utils/DisplayError";
import AboutMeLayout from "./AboutMeLayout";

// Figures
import Figure1A from '../../images/aboutme/Figure1A.png';
import Figure1B from '../../images/aboutme/Figure1B.png';
import Figure2A from '../../images/aboutme/Figure2A.png';
import Figure2B from '../../images/aboutme/Figure2B.png';
import Figure3 from '../../images/aboutme/Figure3.png';
import Figure4 from '../../images/aboutme/Figure4.png';
import Figure5A from '../../images/aboutme/Figure5A.png';
import Figure5B from '../../images/aboutme/Figure5B.png';
import Figure5C from '../../images/aboutme/Figure5C.png';
import Figure5D from '../../images/aboutme/Figure5D.png';
import Figure5E from '../../images/aboutme/Figure5E.png';
import Figure5F from '../../images/aboutme/Figure5F.png';
import Figure5G from '../../images/aboutme/Figure5G.png';
import Figure5H from '../../images/aboutme/Figure5H.png';
import Figure5I from '../../images/aboutme/Figure5I.png';
import Figure5J from '../../images/aboutme/Figure5J.png';
import Figure6 from '../../images/aboutme/Figure6.png';
import Figure7 from '../../images/aboutme/Figure7.png';
import Figure8 from '../../images/aboutme/Figure8.png';
import Figure9 from '../../images/aboutme/Figure9.png';
import Figure10A from '../../images/aboutme/Figure10A.png';
import Figure10B from '../../images/aboutme/Figure10B.png';
import Figure10C from '../../images/aboutme/Figure10C.png';
import Figure10D from '../../images/aboutme/Figure10D.png';


const Manuscript = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [manuscript, setManuscript] = useState<IManuscript>();

    const Figures: string[] = [Figure1A, Figure1B, Figure2A, Figure2B, Figure3, Figure4, Figure5A, Figure5B, Figure5C, Figure5D, Figure5E, Figure5F, Figure5G, Figure5H, Figure5I, Figure5J, Figure6, Figure7, Figure8, Figure9, Figure10A, Figure10B, Figure10C, Figure10D];


    const getChallengeTopics = async () => {
        setLoading(true);

        await GET(MANUSCRIPT_URL)
            .then(response => {

                setManuscript(response.data);

            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false)
            });
    }



    useEffect(() => {
        getChallengeTopics();
    }, []);


    return (
        <>
            {error ? (<DisplayError errors={error} />) : null}

            {loading ? (
                <LCLSpinner />
            ) : (
                <>
                {Figures.length > 0 ? (
                    <AboutMeLayout paper={manuscript} figures={Figures} />
                ):null}
                </>
            )}
        </>
    )
}

export default Manuscript;
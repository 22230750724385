import { Card,Box, CardHeader, Heading, Text,Flex, SimpleGrid, CardBody, useStatStyles, useBreakpointValue, Collapse, SlideFade, ScaleFade, useMediaQuery, Center } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IChallengeExperience } from "../../interface/IChallengeExperience";
import { IPredictionDescription } from "../../interface/IPredictionDescription";
import ChallengeCardModal from "./ChallengeCardModal";

type IPredictionChallenges = {
    header: string,
    data: Array<IChallengeExperience>,
    cardColor: string
}


const PersonaChallenges:React.FC<IPredictionChallenges> = ({
    header, 
    data,
    cardColor
}) => {

    const [ challenge, setChallenge ] = useState<IChallengeExperience>();
    const [ isShowing, setIsShowing ] = useState(false);


    const getColor = (name:string) => {
        return {
            "card": name.indexOf('(') > -1 ? 'gold' : 'green.700',
            "text": name.indexOf('(') > -1 ? '#000' : 'gray.200'
        }
    }

    const openModal = (challenge: IChallengeExperience) => {
        setChallenge(challenge);
        setIsShowing(true);   
    }

    const closeModal = () => {
        setChallenge(undefined);
        setIsShowing(false);
    }

    return(
        <>
        {data ? (
        <Box>
            <CardBody p={0}>
                <Heading as={'h3'} fontSize={'md'} p={2}>
                    {header}
                </Heading>

                <Flex justifyContent={'center'}>

                <SimpleGrid 
                    columns={{base:2, md: 2, lg: 2}} 
                    gap={{base: 2, md: 4}} >
                {data.map((challenge:IChallengeExperience, idx: number) => (
                    
                    <Center
                        p={2}
                        h={['8rem', '9rem', '10rem', '11rem', '12rem']}
                        // h={{base:'8rem', sm:'10rem',md:'11rem'}}
                        // w={{base:'8rem', sm:'10rem',md:'11rem'}}
                        key={idx}
                        rounded={'md'}
                        shadow={'md'}
                        textAlign={'center'}
                        bgColor={cardColor}
                        color={getColor(challenge.name).text}
                        onClick={() => openModal(challenge)}
                        background={getColor(challenge.name).card}
                        _hover={{
                            boxShadow:'0px 0px 10px #ddd', 
                            cursor:'pointer'}}>
                        
                        <Heading 
                            as={'h3'} 
                            fontSize={{md:'1.2rem', sm:'0.8rem'}}>
                            {challenge.name}
                        </Heading>
                    </Center>
                ))}
                </SimpleGrid>
                </Flex>
            </CardBody>
        </Box>
        ) : null }
        {challenge && <ChallengeCardModal 
            onClose={closeModal} 
            isOpen={isShowing} 
            challenge={challenge} />
        }
                

        </>
    )
};

export default PersonaChallenges;
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
    Card,
    CardBody,
    SlideFade,
    useMediaQuery,
    useDisclosure,
    useBreakpointValue,
} from "@chakra-ui/react";


type IProps = {
    isOpen?: boolean,
    children?: React.ReactNode
};

const PredictionDescription: React.FC<IProps> = ({
    isOpen,
    children
}) => {

    const { getDisclosureProps } = useDisclosure()
    const [hidden, setHidden] = useState(!isOpen)
    const [lgScreen] = useMediaQuery('(min-width: 768px)');

    const handleAnimation = () => {
        return lgScreen ? { width: isOpen ? '400px' : 0, height: 'auto' } : { height: isOpen ? 'auto' : 0 }
    }


    return (
        <>

            <Card
                id='sliding-detail-card'
                maxH={'lg'}
                maxW='440px'
                boxShadow='inset 6px 2px 10px rgba(0,0,0,0.05)'
                marginLeft={{
                    base: '0.5!important',
                    sm: '1!important',
                    md: '0!important'
                }}
                marginTop={[
                    '0!important',
                    '0!important',
                    '2!important',
                    '2!important',
                    '2!important']}
                roundedTopLeft={{ base: 0, md: 0 }}
                roundedBottomLeft={{ base: 'lg', md: '0' }}
                roundedTopRight={{ base: 0, md: 'lg' }}
                style={{
                    zIndex: 0,
                }}>

                <motion.div
                    id='motion'
                    {...getDisclosureProps()}
                    hidden={hidden}
                    initial={false}
                    onAnimationStart={() => setHidden(false)}
                    onAnimationComplete={() => setHidden(!isOpen)}
                    animate={handleAnimation}
                    style={{
                        display: 'block',
                        overflow: 'auto',
                        maxWidth: '440px',
                        position: 'relative',
                    }}>

                    <CardBody>
                        <SlideFade
                            in={isOpen}
                            offsetY='10px'
                            delay={0.4}>

                            {children}

                        </SlideFade>
                    </CardBody>
                </motion.div>
            </Card>
        </>
    )
}

export default PredictionDescription;
import { Box, Text, Image, SimpleGrid, Heading, Flex } from "@chakra-ui/react";
import { styles } from "./styles";
import personaBanner from "../../images/landingPage/personaBanner.svg";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@chakra-ui/icons";

const PersonaService = () => {
    return(
        <>
        <Box sx={styles.banner} id="secondary-banner">
            <SimpleGrid p={6} columns={{base: 1, md: 2}} gap={{base:10, md: 20}}>
                <Box sx={styles.image}>
                    <Image 
                        flex={{base: 1, md: 'md'}} 
                        w={500}
                        h={350} 
                        src={personaBanner} />

                </Box>

                <Flex align={'center'} mt={{base:0,md:20}}>
                    <Box>
                        <Heading as="h3" sx={styles.content.h3}>
                            Leadership Challenge Experience
                        </Heading>

                        <Text as="p" 
                            sx={styles.content.p}>
                            Subject matter experts named each leadership challenge experience based on a theoretical understanding of the challenges. Each leadership challenge experience was identified as actions that the leaders would likely need to engage in response to their leadership challenges. 
                        </Text>

                        <Text as="p"
                            fontWeight={700}  
                            sx={styles.content.p} gap={5}>
                            <Link color='teal.500 !important' to={"/experiences"}>Find your Leadership Challenge Experience</Link><ArrowRightIcon />
                        </Text>
                    </Box>
                </Flex>
            </SimpleGrid>
        </Box>
        </>
    )
};

export default PersonaService;
import personalGrowth from "../../images/themes/personalGrowth.svg"
import burnout from "../../images/themes/burnout.svg";
import largeSystem from "../../images/themes/largeSystem.svg";
import { FaTasks } from "react-icons/fa";
import { GiCogsplosion, GiCrystalGrowth } from "react-icons/gi";

type ThemeBackground = {
    image: string;
    icon: any;
}

const PG_THEME_BACKGROUND:ThemeBackground = {
    image: personalGrowth,
    icon: <GiCrystalGrowth />
}

const PD_THEME_BACKGROUND:ThemeBackground = {
    image: burnout,
    icon: <FaTasks />
}

const WLS_THEME_BACKGROUND:ThemeBackground = {
    image: largeSystem,
    icon: <GiCogsplosion />
}



export const ChallengeThemeBackground: Map<string,ThemeBackground> = new Map([    

    ["PersonalGrowth", PG_THEME_BACKGROUND],

    ["PeopleandTaskDemands", PD_THEME_BACKGROUND],
    
    ["WorkingwithinaLargerSystem", WLS_THEME_BACKGROUND],
    
])
import {
    Box,
    Button,
    ButtonGroup,
    CardBody,
    CardFooter,
    Divider,
    Flex,
    Text,
    SimpleGrid,
    Spacer,
    Textarea
} from "@chakra-ui/react";

import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { FormValidationSchema } from "./Fields/FormValidationSchema";

// interfaces and inputs
import { IFormValues } from "../../interface/IFormValues";
import { FormikTextInput } from "./Fields/Input/FormikTextInput";
import { FormikDropdown } from "./Fields/Dropdown/FormikDropdown";

import { experienceInitialValues } from "./Fields/initialValues";
import { sampleData } from "./Fields/sampleData";
import { GET, POST } from "../../utils/fetchData";
import { GEO_LOCATION_URL, TOPIC_PROBABILITIES_URL } from "../../utils/APIURL";
import DisplayErrors from "../../utils/DisplayError";
import LCLSpinner from "../../utils/LCLSpinner";
import { useNavigate } from "react-router-dom";

const ExperienceForm = (props: any) => {

    const [loading, setLoading] = useState(false);
    const [geo_location, setGeoLocation] = useState({});
    const navigate = useNavigate();

    // useEffect(() => {
    //     GET(GEO_LOCATION_URL)
    //     .then(response => {
    //         setGeoLocation(response)
    //     })
    //     .catch(error => { 
    //         console.log(error)
    //     })
    // });

    return (
        <>
            {loading ? (
                <LCLSpinner />
            ) : (
                <Formik<IFormValues>
                    initialValues={experienceInitialValues}
                    // initialValues={sampleData}
                    validationSchema={FormValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setLoading(true)
                        values.geo_location = geo_location;

                        POST(TOPIC_PROBABILITIES_URL, values)
                            .then(response => {

                                navigate('/prediction', { state: { persona: response.data[0] } })

                            })
                            .catch(error => {

                                <DisplayErrors errors={error} />

                            })
                            .finally(() => {

                                setLoading(false);
                                setLoading(false);
                                resetForm();
                                setSubmitting(false);

                            });

                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        resetForm
                    }) => (
                        <Form>
                            <CardBody>
                                {/* We're not using these form elements because the model only uses US data. These data will always be consistent */}
                                {/* <SimpleGrid 
                    gap={8} 
                    w={'100%'} 
                    px={{base: 2, md: 15, lg: 25}}
                    py={{base: 2, md: 15, lg: 25}}
                    columns={{base:1, md:2, lg:4}}>
                    
                    <FormikTextInput 
                        as={Input}
                        name="employee_id"
                        label="Employee ID"
                        helpText="Employee ID"
                        placeholder="Enter your employee ID ... "
                    />
                    
                    <FormikDropdown 
                        name="current_country"
                        label="Current Country"
                        selectOptionText="Select one ... "
                        options={['United States of America']}
                        helpText={'Country where your work is currently based.'}
                    />


                    <FormikDropdown 
                        name="leader_level"
                        label="Leader Level"
                        selectOptionText="Select one ... "
                        options={['Leading Managers']}
                        helpText={'Your current leader level at work.'}
                    />

                    <FormikDropdown 
                        name="lcl_model"
                        label="LCL Model"
                        selectOptionText="Select one ... "
                        options={['AEO']}
                        helpText={'Leadership challenge model.'}
                    />
                </SimpleGrid> */}

                                <Box p={[2, 4, 6]}>
                                    <Text as={'p'}>
                                        Please enter your 3 key leadership challenges and describe them with as much detail as possible. With the detailed description of the challenges, the AI model can provide you with the personalized result of the challenge experience type you are experiencing. By identifying the personalized challenge experience type, you overcome them through a series of exercises (coming soon) and become a more successful leader.

                                    </Text>
                                </Box>


                                <SimpleGrid
                                    gap={8}
                                    w={'100%'}
                                    px={{ base: 2, md: 15, lg: 25 }}
                                    py={{ base: 2, md: 15, lg: 25 }}
                                    columns={{ base: 1, md: 1, lg: 3 }}>

                                    <FormikTextInput
                                        as={Textarea}
                                        h={{ base: 'sm', lg: 'md' }}
                                        name="lcl1"
                                        label="Key Leadership Challenge #1"
                                        helpText="Describe your first key leadership challenge"
                                        placeholder="Enter your leadership challenge ... "
                                    />


                                    <FormikTextInput
                                        as={Textarea}
                                        h={{ base: 'sm', lg: 'md' }}
                                        name="lcl2"
                                        label="Key Leadership Challenge #2"
                                        helpText="Describe your second key leadership challenge"
                                        placeholder="Enter your leadership challenge ... "
                                    />


                                    <FormikTextInput
                                        as={Textarea}
                                        h={{ base: 'sm', lg: 'md' }}
                                        name="lcl3"
                                        label="Key Leadership Challenge #3"
                                        helpText="Describe your third key leadership challenge"
                                        placeholder="Enter your leadership challenge ... "
                                    />

                                </SimpleGrid>


                            </CardBody>

                            <Divider />

                            <CardFooter>
                                <Flex
                                    w={'100%'}
                                    px={{ base: 2, md: 15, lg: 20 }}
                                    minWidth={'max-content'} alignItems={'center'} gap={2}>
                                    <Spacer />
                                    <ButtonGroup>
                                        <Button
                                            rounded={'2xl'}
                                            mr="2"
                                            disabled={isSubmitting || !isValid}
                                            type="submit"
                                            colorScheme={'blue'}>
                                            Submit
                                        </Button>
                                        <Button
                                            rounded={'2xl'}
                                            type="button"
                                            colorScheme={'gray'}
                                            onClick={() =>
                                                resetForm({})}>
                                            Cancel
                                        </Button>
                                    </ButtonGroup>
                                    <Spacer />
                                </Flex>
                            </CardFooter>
                        </Form>
                    )}

                </Formik>
            )}
        </>
    )
};

export default ExperienceForm;
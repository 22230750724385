import { 
    Card,
    CardBody,
    Checkbox,
    Text,
    SimpleGrid,
    Divider,
    Stack,
    CheckboxGroup,
    Box,
    Tag,
    TagLabel,
    CardFooter,
    ButtonGroup,
    Button, 
    Tooltip
} from "@chakra-ui/react";

import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import { IChallengeTopic } from "../../interface/IChallengeTopic";
import { CHALLENGE_URL, GROUP_CLASSIFIER_URL } from "../../utils/APIURL";
import { GET, POST } from "../../utils/fetchData";
import LCLSpinner from "../../utils/LCLSpinner";
import { TOPIC_MAPPING } from "../../utils/TopicMappings";
import { useNavigate } from "react-router-dom";
import { IPersona } from "../../interface/IPersona";
import DisplayError from "../../utils/DisplayError";

const SelectChallenges = () => {

    let payload:any = [];

    const [ persona, setPersona ] = useState<Array<IPersona>>([]);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');
    const [ challengeTopics, setChallengeTopics ] = useState<Array<IChallengeTopic>>([]);
    const navigate = useNavigate();

    const [ challengeArray, setChallenges ] = useState<Array<string>>([]);
    
    const resetChallengeArray = () => {
        setChallenges([]);
    }

    const getChallengeTopics = async () => {
        setLoading(true);

        await GET(CHALLENGE_URL)
            .then(response => {
                setChallengeTopics(response.data);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false)
            });
            
        }
        
        useEffect(() => {
            getChallengeTopics();
            resetChallengeArray();
        },[]);


        const handleCheckboxChange = (event:any) => {
            
            let newArray = [ ...challengeArray, event.target.name ];

            if(challengeArray.includes(event.target.name)) {
                newArray = newArray.filter(name => name !== event.target.name );
            }
            setChallenges(newArray);
        };


    return(
        <>
        {error ? ( <DisplayError errors={error} /> ) : null }
        
        {loading ? (
             <LCLSpinner />
            ) : (
            <Formik
                initialValues={challengeArray}
                onSubmit = { async () => {
                    setLoading(true)
                    challengeArray.forEach(element => {
                        element.replace("’", "'");
                    });
                    
                    payload = Object.keys(TOPIC_MAPPING).map((x) => challengeArray.indexOf(x) + 1);

                    POST(GROUP_CLASSIFIER_URL, payload).then(response => {
                        navigate('/prediction', {state: { persona: response.data.persona }})
                    }).catch(error => {
                        console.log(error);
                    })

            }}
            >
            {({ isSubmitting }) => (
                <Form>
                    <Stack direction={'column'}>

                        <Box p={[2,4,6]}>
                            <Text as={'p'}>
                            Choose 3 key leadership challenges from our carefully curated list by prioritizing them according to their importance to you. By selecting the 3 challenges in order of importance, you can identify the challenge experience type that can help you overcome them through a series of exercises (coming soon) and become a more successful leader.
                             
                            </Text>
                        </Box>

                        <Box p={[2,4,6]}>

                            <CheckboxGroup 
                                colorScheme={'teal'}>

                                <SimpleGrid columns={{base: 1, sm:3, md: 4, lg:5}} gap={4}>
                                {challengeTopics && challengeTopics
                                .filter((c: IChallengeTopic ) => c.leader_level.abbr.includes('LM'))
                                .map((challenge:IChallengeTopic, idx:number) => (
                                    
                                    <Tooltip 
                                    p={2} 
                                    hasArrow
                                    key={idx} 
                                    rounded='lg' 
                                    label={challenge.description}>
                                    <Card 
                                        // key={idx}
                                        fontWeight={400}
                                        id={'challenge-chkbox-'+idx}
                                        _hover={{bgColor: 'teal', color: '#fff'}}>

                                    <CardBody p={1}>
                                        

                                        <Field
                                            as={Checkbox}
                                            p={2}
                                            checked={challengeArray.includes(challenge.name)}
                                            isDisabled={!challengeArray.includes(challenge.name) && challengeArray.length > 2}
                                            onChange={handleCheckboxChange}
                                            name={challenge.name}
                                            value={challenge.name}>
                                            {challenge.name}
                                        </Field>
                                    
                                    </CardBody>

                                    </Card>
                                        </Tooltip>
                                    ))}
                                </SimpleGrid>
                            
                            </CheckboxGroup>
                        </Box>

                        
                        <Box p={[2,4,6]}>
                        
                            {challengeArray.length > 0 ? (
                                <Box mt={4}>
                                    
                                    <Text as="p">
                                        Your 3 leadership challenges
                                    </Text>
                                    {challengeArray.map((c:string, i:number) => (
                                        <Tag 
                                        m={1}
                                        p={2} 
                                        key={i} 
                                        size={'md'} 
                                        variant='solid' 
                                        borderRadius='full' 
                                        colorScheme={'teal'}>
                                            <TagLabel>{c}</TagLabel>
                                        </Tag>
                                    ))}
                                </Box>
                            ) : null}
                        
                        </Box>     

                        <Divider />
                        
                        <CardFooter>
                            <ButtonGroup>
                                <Button 
                                    rounded={'2xl'}
                                    mr="2"
                                    disabled={isSubmitting || !(challengeArray.length === 3)}
                                    type="submit" 
                                    colorScheme={'blue'}>
                                        Submit
                                </Button>
                                <Button 
                                    rounded={'2xl'}
                                    type="button" 
                                    colorScheme={'gray'}
                                    onClick={resetChallengeArray}>
                                        Cancel
                                </Button>
                            </ButtonGroup>
                        </CardFooter>

                    </Stack>
                </Form>  
            )}
            </Formik>

            
        )}
        </>
    )
};

export default SelectChallenges;
import React from "react";
import { 
    FormControl, 
    FormErrorMessage, 
    FormHelperText, 
    FormLabel, 
} from "@chakra-ui/react";
import { ISharedInputProps } from "../../../../interface/ISharedInputProps";

type IProps = ISharedInputProps & {
    id?: string;
    children?: React.ReactNode
};

export const InputWrapper: React.FC<IProps> = ({
    id,
    label,
    name,
    children,
    helpText,
    errorText,
}) => (
    <FormControl>
        <FormLabel htmlFor={id || name}> {label} </FormLabel>
            {children}

        { !errorText ? (
        <FormHelperText>
            {helpText}
        </FormHelperText>
        ) : (
        <FormErrorMessage>
            {errorText}
        </FormErrorMessage>
        )}
    </FormControl>
)
import { Card, CardBody, Tabs, TabPanels, TabPanel, TabList, Tab } from '@chakra-ui/react';
import FiguresSubTab from './FiguresSubTab';

const FiguresTab = (props: any) => {

    return (
        <>

            <Card
                p={0}
                overflow={'hidden'}
                variant='elevated'
                direction={{ base: 'column', sm: 'row' }}>

                {props.sub.figures?.length > 0 ? (

                    <CardBody>

                        <Tabs defaultIndex={0} size={'lg'} >
                            <>
                                <TabList>
                                    {props.sub.figures.map((figure: any, id: number) => (

                                        <Tab key={id}>{figure.name}</Tab>

                                    ))}

                                </TabList>


                                <TabPanels>
                                    {props.sub.figures.map((figure: any, fnid: number) => (

                                        <TabPanel key={fnid}>
                                            <FiguresSubTab
                                                figures={props.figures}
                                                figureLegend={figure.legend}
                                                figureTitle={figure.title}
                                                figureName={figure.name} />
                                        </TabPanel>

                                    ))}

                                </TabPanels>
                            </>
                        </Tabs>


                    </CardBody>

                ) : null}

            </Card>


        </>
    )
};

export default FiguresTab;


import { useEffect, useState } from "react";
import { SUPPLEMENTAL_METHOD_URL } from "../../utils/APIURL";
import { GET } from "../../utils/fetchData";
import { ISupplementalMethod } from "../../interface/IManuscript";
import LCLSpinner from "../../utils/LCLSpinner";
import DisplayError from "../../utils/DisplayError";
import AboutMeLayout from "./AboutMeLayout";

// Figures
import SuppFigure1 from '../../images/aboutme/SuppFigure1.png';
import SuppFigure2 from '../../images/aboutme/SuppFigure2.png';
import SuppFigure3 from '../../images/aboutme/SuppFigure3.png';
import SuppFigure4 from '../../images/aboutme/SuppFigure4.png';
import SuppFigure5 from '../../images/aboutme/SuppFigure5.png';
import SuppFigure6 from '../../images/aboutme/SuppFigure6.png';
import SuppFigure7 from '../../images/aboutme/SuppFigure7.png';
import SuppFigure8 from '../../images/aboutme/SuppFigure8.png';
import SuppFigure9 from '../../images/aboutme/SuppFigure9.png';


const SupplementalMethod = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [supplementalMethod, setManuscript] = useState<ISupplementalMethod>();

    const Figures: string[] = [SuppFigure1, SuppFigure2, SuppFigure3, SuppFigure4, SuppFigure5, SuppFigure6, SuppFigure7, SuppFigure8, SuppFigure9];

    const getChallengeTopics = async () => {
        setLoading(true);

        await GET(SUPPLEMENTAL_METHOD_URL)
            .then(response => {

                setManuscript(response.data);

            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false)
            });
    }



    useEffect(() => {
        getChallengeTopics();
    }, []);


    return (
        <>
            {error ? (<DisplayError errors={error} />) : null}

            {loading ? (
                <LCLSpinner />
            ) : (
                <>
                {Figures.length > 0 ? (
                    <AboutMeLayout paper={supplementalMethod} figures={Figures}/>
                ) : null }
                </>
            )}
        </>
    )
}

export default SupplementalMethod;
import "@fontsource/poppins"
import { 
    Box,
    Flex, 
    Heading,
    Spacer,
 } from "@chakra-ui/react"


const SectionHeader = (props:any) => {
    return(
        <>
        <Flex 
            sx={headerStyles.content}
            >    
            <Spacer />
            <Heading 
                as='h2' 
                sx={headerStyles.h3}>
                {props.title}
            </Heading>
            <Spacer />
        </Flex>
        </>
    )
}

export default SectionHeader;


const headerStyles = {
    content:{
        flex: 1,
        pt: ['5px', null, null, null, '20px', '20px'],
        pb: ['5px', null, null, null, '20px', '20px'],
    },
    h3: {
        // letterSpacing: '0.03em',
        fontSize:['1.5rem', '1.6rem', '1.9rem', '2.0rem'],
        fontFamily: 'Roboto',
        fontWeight: 300
    }
}
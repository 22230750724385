import { Input } from "@chakra-ui/react";
import React from "react";
import { InputWrapper } from "./InputWrapper";
import { TextInputProps } from "../Props/TextInputProps";
import { Field } from "formik";

export const TextInput: React.FC<TextInputProps> = (props) => {
    const {
        label,
        errorText, 
        helpText, 
        onKeyDownEnter, 
        onChangeText,
        ...inputProps
    } = props;

    return(
        <>
        <InputWrapper 
            id={inputProps.id}
            as={inputProps.as}
            name={inputProps.name}
            label={label}
            helpText={helpText}
            errorText={errorText}>

            <Field variant={'filled'} {...inputProps}
                onKeyDown={(e:any) => {
                    
                    // override on keydown to fire the enter key down event
                    if(e.key == 'Enter') {
                        onKeyDownEnter?.();
                    }

                inputProps.onKeyDown?.(e);
                }}
                onChange={(e:any) => {
                    // override on change to fire change text event
                    inputProps.onChange?.(e);
                    onChangeText?.(e.target.value);
                }}
            />
            </InputWrapper>
        </>
    )
}
export const TOPIC_MAPPING = {
    
        "Challenging Business Context": "Achieving_Results_in_a_Challenging_Business_Context_top3_rank",
        "Ineffective Interpersonal Style": "Adapting_Leadership_Style_top3_rank",
        "Personal Limitations": "Confronting_Personal_Limitations_top3_rank",
        "Cross-Functional Influence": "Influencing_Across_the_Organization_top3_rank",
        "Staffing Issues": "Managing_Others_to_Accomplish_Work_top3_rank",
        "Competing People and Project Priorities": "Managing_People_and_Projects_top3_rank",
        "New or Ambiguous Responsibilities": "New_or_Ambiguous_Management_Responsibilities_top3_rank",
        "Development Gaps: Yours and Others": "Professional_Development:_Yours_&_Others_top3_rank",
        "Accountability for Others’ Work": "Promoting_Culture_Change_and_Process_Improvement_top3_rank",
        "Adverse Work Environment": "Talent_Management_Constraints_top3_rank",
}
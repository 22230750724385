export const experienceInitialValues = {
    lcl1: '',
    lcl2: '', 
    lcl3: '',
    employee_id: '',
    current_country: 'United States of America',
    leader_level: 'Leading Managers',
    lcl_model: 'AEO',
    geo_location: {}, 
    created_on: new Date().toISOString().slice(0,10),
    created_at: new Date().toISOString().slice(10)
}
import "@fontsource/poppins";

import { FaBars, FaTimes } from "react-icons/fa";
import { Logo } from "./Logo";
import { ColorModeSwitcher } from "../../utils/ColorModeSwitcher"

import { 
    Box,
    Text,
    Flex, 
    chakra, 
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    IconButton,
    HStack,
    Collapse,
    Spacer
} from "@chakra-ui/react";

import { DesktopNav } from "./nav/DesktopNav";
import { MobileNav } from "./nav/MobileNav";

const Header = () => {
    const { isOpen, onToggle } = useDisclosure();

    return(
        <chakra.header 
            bg={'transparent'} 
            color={useColorModeValue('gray.600', 'white')}
            minH={'50px'}
            py={{ base: 2 }}
            px={{ base: 4}}
            borderBottom={1}
            borderStyle={'solid'}
            id="header" 
            borderColor={useColorModeValue('gray.200', 'gray.900')}>
            <Flex
                h={16} w="100%"
                px="2" py="5"
                alignItems={'center'}
                justifyContent="space-between">
                    <Flex
                        w={'sm'}
                        flex={{ base: 1, md: 'xs' }}
                        ml= {{ base: -2 }}
                        display={{base: 'flex', md: 'none'}}>
                        <IconButton 
                            size={'lg'}
                            icon={ 
                                isOpen ? <FaTimes /> : <FaBars />
                            }
                            aria-label={'Open Menu'}
                            variant={'ghost'}
                            onClick={ onToggle }>
                        </IconButton>
                    </Flex>
                    <Spacer flex={{base: 1, md: 'none'}} />
                    <Flex 
                        flex={{ base: 1 }}
                        align={'center'}
                        justify={{ base: 'center', md: 'start' }}>

                        <Logo 
                            h={{base:"7vmin", sm: '5vmin', md:'3vmin'}} 
                            pr={'4px'}
                            pointerEvents="none" />
                        
                        <Text
                            as='b'
                            fontWeight={800}
                            fontSize={{ base:'sm', md:'lg', lg: '2xl' }}
                            fontFamily={'poppins'}
                            display = {{ base: 'none', md: 'flex'}}>
                            Challenge Experiences.
                        </Text>
                        
                        <Text
                            as='b'
                            fontWeight={800}
                            fontSize={'xl'}
                            fontFamily={'poppins'}
                            display = {{ base: 'flex', md: 'none'}}>
                            LCE
                        </Text>

                    </Flex>

                 {/* Navigation  */}
                 <Flex
                    display={{ base: 'none', md: 'flex' }}
                    ml={10}>
                        <DesktopNav />
                </Flex>
                <Spacer flex={{base: 1, md: 'none'}} />
                {/* Theme selector */}
                <Flex w={'auto'}>
                    <ColorModeSwitcher />
                </Flex>

            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>                    
                
        </chakra.header>
    );
};

export default Header;
import { Box, HStack, VStack, Link, Popover, PopoverContent, PopoverTrigger, useColorModeValue, Divider } from "@chakra-ui/react"
import { DesktopSubNav } from "./DesktopSubNav";
import { NAV_ITEMS } from "./NavItems";

export const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popOverContentBgColor = useColorModeValue('white', 'gray.800');

    return(
        <>
        <HStack spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover 
                        isLazy={true}
                        trigger={'hover'}
                        placement={'bottom-start'}>
                            <PopoverTrigger>
                                <Link
                                    p={2}
                                    href={navItem.href ?? '#'}
                                    fontFamily={'poppins'}
                                    fontSize={'md'}
                                    fontWeight={500}
                                    color={linkColor}
                                    _hover={{
                                        textDecoration: 'none', 
                                        color: linkHoverColor,
                                    }}>
                                        {navItem.label}
                                </Link>
                            </PopoverTrigger>
                            
                            {navItem.children && (
                                <PopoverContent
                                    justifyContent={'left'}
                                    border={0}
                                    boxShadow={'xl'}
                                    bg={popOverContentBgColor}
                                    p={4}
                                    rounded={'md'}
                                    minW={'sm'}>
                                        <VStack align={'left'}>
                                            {navItem.children.map((child) => (
                                                <DesktopSubNav key={child.label} {...child} />
                                                
                                            ) )}
                                        </VStack>
                                            
                                </PopoverContent>
                            )}
                    </Popover>
                </Box>
            ))}
        </HStack>
        </>
    )
}
import { Divider } from '@chakra-ui/react';
import Banner from './Banner'
import PersonaService from './PersonaService';

const Home = () => {

    return(
        <>
        <Banner /> 
        <PersonaService />
        </>
    )
};

export default Home;
import axios from 'axios';
import { useEffect, useState } from "react";

import { Flex } from "@chakra-ui/react";

import { ILeaderLevel } from "../../interface/ILeaderLevel";
import SectionHeader from '../../utils/SectionHeader';
import { LEADER_LEVEL_URL } from '../../utils/APIURL';


import LCLSpinner from '../../utils/LCLSpinner';
import DisplayError from '../../utils/DisplayError';
import LeaderLevelCard from './LeaderLevelCard';
import { GET } from '../../utils/fetchData';


const LeaderLevels = () => {
    
    const [levels, setLevels] = useState<Array<ILeaderLevel>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const getLevels = async () => {

        setLoading(true);

        await GET(LEADER_LEVEL_URL)
            .then(response => {
                setLevels(response.data)
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getLevels();
    }, []);

    
    return(
        <>
        <Flex>
            <SectionHeader title={'Leader Levels'} />
        </Flex>
        
        {error ? ( <DisplayError errors={error} /> ) : null }
        
        {loading ? (
            <LCLSpinner />
        ) : (
        <Flex 
            align={'center'} justifyContent={'center'}>
            <LeaderLevelCard levels={levels} />
        </Flex>
        )}
        </>
    )
}

export default LeaderLevels;

import { 
    Drawer, 
    DrawerBody, 
    DrawerContent, 
    DrawerHeader, 
    DrawerOverlay, 
    DrawerCloseButton,
    CardHeader,
    Heading,
    Card,
    CardBody,
    Avatar,
    SimpleGrid,
    Flex,
    Divider,
    CardFooter,
    Badge
} from "@chakra-ui/react";
import { IChallengeExperience } from "../../interface/IChallengeExperience";

const PersonaDrawer = (props:any) => {
    return(
        <>
        <Drawer 
            placement="right" 
            onClose={props.onClose} 
            isOpen={props.isOpen}>
            <DrawerOverlay/>

            <DrawerContent>
                <DrawerCloseButton/>
                <DrawerHeader textTransform={'uppercase'}>{ props.title }</DrawerHeader>
                <Divider />
                <DrawerBody>
                    <SimpleGrid column={1} gap={6}>
                    {props.description instanceof Array
                    ? props.description.map((c:IChallengeExperience, i:number) => (
                        <Card 
                            variant={'outline'}
                            size={'sm'} 
                            key={i} 
                            wordBreak={'break-word'}>
                            <CardHeader>
                                <Flex w={'auto'} gap={2} align={'center'}>
                                <Avatar
                                    size={'sm'}
                                    name={c.order.toString()}/>
                                <Heading
                                    fontSize={'sm'}>
                                    {c.name}
                                </Heading>
                                </Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <p>
                                    {c.description}
                                </p>
                            </CardBody>
                            <Divider />
                            <CardFooter>
                                <Badge variant={'outline'}>
                                    {c.theme}
                                </Badge>
                            </CardFooter>
                        </Card>
                        
                    ))  
                    : <p>{props.description}</p>
                    }
                    </SimpleGrid>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    )
};

export default PersonaDrawer;
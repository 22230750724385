import { Tab, TabPanels, Tabs, TabList, TabPanel } from "@chakra-ui/react";
import Manuscript from "./Manuscript";
import SupplementalMethod from "./SupplementalMethod";

const AboutMeHome = () => {
    return(
        <>

            <Tabs>
                <TabList>
                    <Tab>Manuscript</Tab>
                    <Tab>Supplemental method</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel><Manuscript /></TabPanel>
                    <TabPanel><SupplementalMethod /></TabPanel>
                </TabPanels>
            </Tabs>


        </>
    )
}

export default AboutMeHome;
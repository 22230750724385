import * as Yup from "yup";

export const FormValidationSchema = Yup.object().shape({
    lcl1: Yup.string()
        .min(7, "Please enter at least 4 words.")
        .required("Please enter the leadership challenge #1"),
    lcl2: Yup.string()
        .min(7, "Please enter at least 4 words.")
        .required("Please enter the leadership challenge #2"),
    lcl3: Yup.string()
        .min(7, "Please enter at least 4 words.")
        .required("Please enter the leadership challenge #3"),
    employee_id: Yup.string(),
    current_country: Yup.string()
        .required("Please select your current country."),
    leader_level: Yup.string()
        .required("Please select your leader level."),
    lcl_model: Yup.string()
        .required("Please select the lcl model.")
});
import { INavItem } from "../../../interface/INavItem";

export const NAV_ITEMS: Array<INavItem> = [
    {
        label: 'Home',
        href: '/'
    },
    {
        label: 'Find your Experience Type',
        href: '/experiences'
    },
    {
        label: 'Resources', 
        children: [
            {
                label: 'Challenges', 
                subLabel: 'Leadership Challenges',
                // children: [],
                href: '/challenge-topics'
            },
            {
                label: 'Leader Levels', 
                subLabel: 'Leader Levels',
                // children: [],
                href: '/leader-levels'
            },
            {
                label: 'Experiences', 
                subLabel: "Leadership Challenge Experience",
                // children: [],
                href: '/personas'
            },
            {
                label: 'Themes', 
                subLabel: 'Leadership Challenge Themes',
                href: '/topic-themes'
            }
        ]
    },
    {
        label: 'Additional Information',
        href: '/about-me'
    },
];
import {
    Box,
    Card,
    CardBody,
    Divider,
    Heading,
    IconButton,
    Image,
    Spacer,
    Stack,
    Text,
    useDisclosure,
    Tooltip,
    UnorderedList,
    ListItem,
    Flex,
    TableContainer,
    Table,
    TableCaption,
    Thead, Tr, Th, Tbody, Td, Tfoot,
    useColorModeValue,
    VStack,
    Center,
    useMediaQuery,
    ScaleFade
} from "@chakra-ui/react";
import { FaInfoCircle, FaQuestion } from "react-icons/fa";
import { GiPull } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import balancing from '../../images/personas/balancing.svg';
import { PersonaBackground } from '../../utils/PersonaBackgrounds';
import { IPersona } from "../../interface/IPersona";
// import { persona } from "./persona";
import PersonaDescription from "./PersonaDescription";
import SectionHeader from "../../utils/SectionHeader";
import PersonaChallenges from "./PersonaChallenges";
import PredictionDescription from "./PredictionDescription";
import { styles } from "../LandingPage/styles";


const PredictionResult = (props: any) => {

    // let persona:IPersona;

    // For opening drawer
    const { isOpen: isWhyOpen, onClose: onWhyClose, onToggle: onWhyToggle } = useDisclosure();
    const { isOpen: isHowOpen, onClose: onHowClose, onToggle: onHowToggle } = useDisclosure();
    const { isOpen: isChallengesOpen, onClose: onChallengesClose, onToggle: onChallengesToggle } = useDisclosure();

    const cardColor = useColorModeValue('#FFFFFF', 'gray.900')
    const bgcolor = useColorModeValue('#F6F8FB', 'gray.600')

    const [largeScreen] = useMediaQuery('(min-width: 768px)');

    const location = useLocation();
    const persona: IPersona = location.state.persona;

    const closeAll = () => {
        onWhyClose();
        onHowClose();
        onChallengesClose();
    }

    return (
        <>
            <SectionHeader title={'Leadership Challenge Experience'} />
            {persona ? (


                <Center
                    background={bgcolor}>

                    {/* main container to hold the cards */}
                    <ScaleFade initialScale={0.9} in={true}>

                        <VStack>
                            <Box pt={10}>
                                <Text as="p" sx={styles.content.p}>
                                    Your Leadership Challenge Experience is <strong>{persona.name}</strong>.
                                </Text>
                                <Text as="p" sx={styles.content.p}>
                                    Go ahead and look around! Learn about your Leadership Challenge Experience type by clicking on the buttons.
                                </Text>
                            </Box>

                            <Stack
                                id='prediction-stack'
                                pt={10} pb={10} pl={[4, 4, 0, 0, 0]} pr={[4, 4, 0, 0, 0]}
                                direction={{ base: 'column', md: 'row' }}>

                                <Card
                                    id='prediction-card'
                                    maxW='md'
                                    h={['auto', 'auto', 'auto', "525px"]}
                                    mb={[0, 0, 0, 4, 4]}
                                    style={{ zIndex: 1 }}
                                    bgColor={cardColor}>

                                    {/* TDA Group name button - top left corner */}
                                    <Tooltip label='Persona Group'>
                                        <IconButton
                                            id='tda-group-num'
                                            m={2} p={4} w={10}
                                            position='absolute'
                                            variant={'outline'}
                                            cursor={'default'}
                                            border={'3px solid #ddd'}
                                            aria-label="TDA group">

                                            <Text
                                                as={'span'}
                                                color={'gray.400'}
                                                fontWeight={200}
                                                letterSpacing={2}
                                                fontSize={'1.5rem'}
                                                fontFamily={'anton'}>
                                                {parseInt(persona.tda_group_name.match(/\d+/g)![1]).toLocaleString('en-US', {
                                                    minimumIntegerDigits: 2,
                                                    useGrouping: false
                                                })}
                                            </Text>
                                        </IconButton>
                                    </Tooltip>


                                    <Image
                                        id='persona-image'
                                        objectFit={'cover'}
                                        src={PersonaBackground.get(persona.name.toLowerCase())} />


                                    <CardBody p={0}>

                                        <Stack
                                            id='card-control-stack'
                                            direction={{ base: 'column', md: 'row' }}>

                                            <Box
                                                id='icon-box'
                                                pt={{ base: 0, md: 2 }}>

                                                <Stack
                                                    id='icon-stack'
                                                    direction={{ base: 'row', md: 'column' }}
                                                    gap={0}>
                                                    <Tooltip label='Why'>
                                                        <IconButton
                                                            p={[6, 4]}
                                                            color={'blue.400'}
                                                            rounded={0}
                                                            variant={'ghost'}
                                                            aria-label="control button Why"
                                                            onClick={() => { closeAll(); onWhyToggle(); }}
                                                            icon={<FaQuestion />} />
                                                    </Tooltip>

                                                    <Divider h={'auto'} orientation={largeScreen ? 'horizontal' : 'vertical'} />

                                                    <Tooltip label='How'>
                                                        <IconButton
                                                            p={[6, 4]}
                                                            color={'blue.400'}
                                                            rounded={0}
                                                            variant={'ghost'}
                                                            aria-label="control button How"
                                                            onClick={() => { closeAll(); onHowToggle(); }}
                                                            icon={<FaInfoCircle />} />

                                                    </Tooltip>

                                                    <Divider h={'auto'} mt={0} orientation={largeScreen ? 'horizontal' : 'vertical'} />

                                                    <Tooltip label='Challenges'>
                                                        <IconButton
                                                            p={[6, 4]}
                                                            color={'blue.400'}
                                                            rounded={0}
                                                            variant={'ghost'}
                                                            fontSize={'2xl'}
                                                            aria-label="control button Challenge"
                                                            onClick={() => { closeAll(); onChallengesToggle(); }}
                                                            icon={<GiPull />} />
                                                    </Tooltip>
                                                </Stack>
                                                {/* End of icon stack */}

                                                <Spacer />
                                            </Box>


                                            <Divider
                                                h={'auto'}
                                                p={0}
                                                m={'0 !important'}
                                                orientation={largeScreen ? 'vertical' : 'horizontal'} />

                                            {/* Persona description */}
                                            <Box
                                                id='persona-desc-box'
                                                pt={6}
                                                pr={4}
                                                pl={4}>
                                                <Heading
                                                    as={'h1'}
                                                    size={'lg'}>
                                                    {persona.name}
                                                </Heading>

                                                <Text as={'p'} py={4} mb={4}>
                                                    {persona.description}
                                                </Text>
                                            </Box>
                                            {/* End persona description */}
                                        </Stack>
                                        {/* End all box stack */}
                                    </CardBody>
                                </Card>
                                {/* End card design for persona description */}


                                {/* Persona details stack */}
                                <Stack
                                    id='detail-stack'
                                    mt={['0 !important']}
                                    marginLeft={{ md: '0!important' }}
                                    direction={{ base: 'column', md: 'row' }}>

                                    <PredictionDescription isOpen={isWhyOpen}>
                                        <PersonaDescription header='Why' data={persona.why} />
                                    </PredictionDescription>

                                    <PredictionDescription isOpen={isHowOpen}>
                                        <PersonaDescription header='How' data={persona.how} />
                                    </PredictionDescription>

                                    <PredictionDescription isOpen={isChallengesOpen}>
                                        <PersonaChallenges cardColor={cardColor} header='Challenge Experiences' data={persona.challenge_experiences} />
                                    </PredictionDescription>

                                </Stack>
                                {/* End persona detail stack */}
                            </Stack>

                            {/* Legend */}
                            <TableContainer pb={10}>
                                <Table variant='simple' size='sm'>
                                    <TableCaption placement='top'>Here're just a few things to keep in mind while you review your <strong>Leadership Challenge Experience.</strong></TableCaption>
                                    <Tbody>
                                        <Tr>
                                            <Td colSpan={2}>The card below shows your Leadership Experience Type with the description.</Td>
                                        </Tr>
                                        <Tr>
                                            <Td><FaQuestion /></Td>
                                            <Td>Shows you the <strong>WHY</strong>.</Td>
                                        </Tr>
                                        <Tr>
                                            <Td><FaInfoCircle /></Td>
                                            <Td>Shows you the <strong>HOW</strong>.</Td>
                                        </Tr>
                                        <Tr>
                                            <Td><GiPull /></Td>
                                            <Td>Shows you the challenges that characterizes your <strong>Leadership Challenge Experience.</strong></Td>
                                        </Tr>
                                    </Tbody>

                                    <Tfoot>
                                        <Tr>
                                            <Td colSpan={2}><strong>Note:</strong> The challenges in green boxes indicate &gt;20% of the average response while the yellow indicates &gt;10% of the average response.</Td>
                                        </Tr>
                                    </Tfoot>
                                </Table>
                            </TableContainer>

                            <Box pb={10}>
                                <Text as="p" sx={styles.content.p}>
                                    <strong>Coming Soon: </strong> You'll be able to receive valuable contents to help you alleviate your challenges and become a successful and effective leader.
                                </Text>
                            </Box>


                        </VStack>

                    </ScaleFade>

                </Center>
            ) : null}
        </>
    )
};

export default PredictionResult;
import axios from "axios";

interface Headers {
    [key: string]: string;
}

var headers: Headers = {}

headers['Content-Type'] = 'application/json; charset=utf-8'

export const GET = (url: string) => {
    return axios.get(`${url}`, { headers });
};


export const POST = (url: string, data: any) => {

    return axios.post(url, data, { headers });

};


import { Card, CardBody, CardHeader, Center, Collapse, Divider, Flex, HStack, IconButton, SlideFade, Spacer, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { IPersona } from "../../interface/IPersona";
import SectionHeader from "../../utils/SectionHeader";
import ExperienceForm from "./ExperienceForm";
import SelectChallenges from "./SelectChallenges";
import DataInputOption from "./DataInputOption";
import { BiArrowBack } from "react-icons/bi";

const ExperienceInput = () => {

    const cardColor = useColorModeValue('#FFFFFF', 'gray.900')
    const bgcolor = useColorModeValue('#F6F8FB', 'gray.600')
    const [ persona, setPersona ] = useState<Array<IPersona>>([]);

    // display or hide options to predict the persona. This is the card that appears in Experiences page.
    const [ showOption, setshowOption ] = useState(true);
    

    // displays or hides the card that includes challenges selection or challenges entry. This is a slidefade that slides up when an item is selection 
    const { isOpen: isShowSelection, onToggle: toggleShowSelection } = useDisclosure()

    // displays or hides options for selecting challenges
    const { isOpen: isCheckboxSelectionOpen, onToggle: onCheckboxSelectionToggle } = useDisclosure();

    // displays or hides entry form for the challenges
    const { isOpen: isEntryFormSelectionOpen, onToggle: onEntryFormSelectionToggle } = useDisclosure();


    const handlePersona = (value:any) => {
        setPersona(value);
    }

    const handleSelection = () => {
        onCheckboxSelectionToggle(); 
        toggleShowSelection();
        setshowOption(false);
        
    }


    const handleEntryForm = () => {
        onEntryFormSelectionToggle();
        toggleShowSelection();
        setshowOption(false);
    }

    const handleBackbutton = () => {
        { isCheckboxSelectionOpen ? onCheckboxSelectionToggle() : null }
        { isEntryFormSelectionOpen ? onEntryFormSelectionToggle() : null }
        setshowOption(true); 
        toggleShowSelection();
    }


    return(
        <>
        <Center
            background={bgcolor}
            p={[2,4,6]}>                
            
            <VStack>

                <Collapse in={showOption} delay={0.2}>

                    {/* opens selection card for choosing entry form or challenge selection. This will be hidden when a selection is made */}
                    <DataInputOption showOption={showOption} onCheckboxSelectionToggle={handleSelection} onEntryFormSelectionToggle={handleEntryForm} />

                </Collapse>

                <SlideFade in={isShowSelection}>
                    <Card
                        size={'lg'}
                        bgColor={cardColor}
                        variant={'elevated'}
                        rounded='lg'>
                        <CardHeader p={[2,4,6]}>
                            <Flex align={'center'}>

                                <IconButton 
                                    mr={{base:2}} 
                                    aria-label="back" 
                                    variant={'outline'} 
                                    icon={<BiArrowBack />} 
                                    onClick={handleBackbutton} />
                                
                                <SectionHeader
                                    title={'Leadership Challenge Experience'} />
                                
                            </Flex>
                        
                        </CardHeader>

                        <Divider />

                        <CardBody p={0}>
                            
                             {isCheckboxSelectionOpen ? <SelectChallenges /> : null }

                             {isEntryFormSelectionOpen ? <ExperienceForm setPersona={handlePersona} /> : null }
                        
                        </CardBody>



                        {/* Form */}
                        
                    </Card>                 
                </SlideFade>
            </VStack>
        </Center>
        </>
    )
};

export default ExperienceInput;
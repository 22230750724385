import { 
    Flex, 
    Text, 
    Link, 
    Stack, 
    useColorModeValue, 
    useDisclosure, 
    Icon, 
    Collapse 
} from "@chakra-ui/react"
import { FaChevronDown } from "react-icons/fa";
import { INavItem } from "../../../interface/INavItem";
import { NAV_ITEMS } from "./NavItems"

export const MobileNav = () => {
    return(
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
                {NAV_ITEMS.map((navItem) => (
                    <MobileNavItem key={navItem.label} {...navItem} />
                ))}
        </Stack>
    );
};



export const MobileNavItem = ({ label, children, href }: INavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none'
                }}>
                    <Text
                        fontWeight={500}
                        color={useColorModeValue('gray.600', 'gray.200')}>                            
                            {label}
                    </Text>
                    {children && (
                        <Icon
                            as={FaChevronDown}
                            transition={'all .25s ease-in-out'}
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            w={6}
                            h={6} />
                    )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                        {children && 
                            children.map((child) => (
                                <Link 
                                    key={child.label}
                                    py={2}
                                    href={child.href}>
                                        {child.label}
                                    </Link>
                            ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};
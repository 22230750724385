import balancing from '../images/personas/balancing.svg';
import influencing from '../images/personas/influencing.svg';
import uplifting from '../images/personas/uplifting.svg';
import advocating from '../images/personas/advocating.svg';
import optimizing  from '../images/personas/optimizing.svg';
import illuminating from '../images/personas/illuminating.svg';
import adapting from '../images/personas/adapting.svg';
import connecting from '../images/personas/connecting.svg';
import questioning from '../images/personas/questioning.svg';
import stretching from '../images/personas/stretching.svg';


export const PersonaBackground = new Map<string, string>(
[
        ["balancing", balancing],
        ["influencing", influencing], 
        ["uplifting", uplifting], 
        ["advocating", advocating], 
        ["optimizing", optimizing], 
        ["illuminating", illuminating], 
        ["adapting", adapting], 
        ["connecting", connecting], 
        ["questioning", questioning], 
        ["stretching", stretching]
]
);
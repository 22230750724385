import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  Divider,
} from "@chakra-ui/react";
import { 
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// Components
import Header from "./components/Navigation/Header";
import Home from "./components/LandingPage/Home";
import TopicThemes from "./components/Themes/ChallengeThemes";
import LeaderLevels from "./components/LeaderLevels/LeaderLevels"
import Personas from "./components/Persona/Personas";
import ChallengeTopic from "./components/Challenges/ChallengeTopics";
import Footer from './components/Footer';
import ExperienceInput from "./components/Experiences/Experiences";
import PredictionResult from "./components/PredictionResult/PredictionResult";
import AboutMeHome from "./components/AboutMe/AboutMeHome";

export const App = () => (

  <section className="LCE-App">
    <Router>
      <ChakraProvider theme={theme}>
        {/* Navigation header component */}
        <Header />

        {/* Body */}
        <Box>
          <Routes>

            <Route path="/" 
                    element={<Home />} />

            <Route path="/experiences" 
                    element={<ExperienceInput />} />

            <Route path="/leader-levels" 
                    element={<LeaderLevels />} />

            <Route path="/topic-themes" 
                    element={<TopicThemes />} />

            <Route path="/challenge-topics"
                    element={<ChallengeTopic />} />

            <Route path="/personas"
                    element={<Personas />} />

            <Route path="/prediction" 
                    element={<PredictionResult />} />

            <Route path="/about-me"
                    element={<AboutMeHome />} />

            <Route path="/status.txt" element={<AboutMeHome />} />

          </Routes>
        </Box>
      </ChakraProvider>
    </Router>
    <Footer />
  </section>
)
